<template>
  <BModal
    id="modal-gamification"
    ref="modal-gamification"
    hide-footer
    size="md"
    hide-header
    centered
    hide-header-close
    @show="onOpen"
    @hide="handleClose"
  >
    <div
      v-if="!isSuccess"
      class="p-[32px]"
    >
      <div class="text-[#333333] text-center font-bold text-[24px]">
        Klaim Akun Whitelist
      </div>
      <div
        class="text-[#626262] text-center leading-[24px] text-[16px] mt-[8px]"
      >
        Isi data diri kamu dengan benar untuk dapat kami kontak dan claim segera
      </div>
      <ValidationObserver #default="{ invalid }">
        <div class="mt-[32px]">
          <ValidationProvider
            #default="{ errors }"
            name="Nama Lengkap"
            :rules="{ required: true, minFullname, maxFullname, onlyAlphabet }"
          >
            <BFormGroup>
              <template #label>
                <div class="text-[#333333] required-form">
                  Nama Lengkap
                </div>
              </template>
              <BInputGroup class="disable-icon">
                <BFormInput
                  v-model="form.fullname"
                  :state="errors.length > 0 ? false : null"
                />
              </BInputGroup>
              <small class="text-danger">{{ errors[0] }}</small>
            </BFormGroup>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            name="Nomor HP"
            :rules="{ required: true, startWith8, minHP, maxHP }"
          >
            <BFormGroup>
              <template #label>
                <div class="text-[#333333] required-form">
                  Nomor HP
                </div>
              </template>
              <BInputGroup
                class="number-phone"
                prepend="+62"
              >
                <BFormInput
                  v-model="form.number"
                  debounce="300"
                  :state="errors.length > 0 || !checkWa ? false : null"
                  @keydown="preventNonNumeric($event)"
                  @paste="preventNumberPaste"
                />
              </BInputGroup>
              <small class="text-danger">{{
                errors[0]
                  ? errors[0]
                  : !checkWa
                    ? 'Nomor harus terhubung dengan Whatsapp'
                    : null
              }}</small>
            </BFormGroup>
          </ValidationProvider>
          <div class="flex gap-[10px]">
            <BButton
              variant="outline-primary"
              class="w-full"
              @click="$bvModal.hide('modal-gamification')"
            >
              Batal
            </BButton>
            <BButton
              variant="primary"
              class="w-full"
              :disabled="invalid || !checkWa"
              @click="submitForm"
            >
              Ajukan
            </BButton>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <div
      v-if="isSuccess && checkWa === true"
      class="p-[32px]"
    >
      <div class="flex justify-center">
        <img
          src="https://storage.googleapis.com/komerce/core/icon-popup-success.png"
          alt="success"
        >
      </div>
      <div class="text-[#333333] text-center font-bold text-[24px] mt-[20px]">
        Klaim Data Berhasil
      </div>
      <div class="text-center text-[#626262] text-[16px] mt-[8px]">
        Pengajuan klaim akun Whitelist sedang berlangsung, apabila ada
        pertanyaan tambahan bisa
        <a
          href="https://wa.me/6281328014170"
          class="text-[#08A0F7]"
        >
          contact admin</a>
      </div>
      <BButton
        variant="primary"
        class="w-full mt-[32px]"
        @click="handleClaimGamification"
      >
        Tutup
      </BButton>
    </div>
  </BModal>
</template>

<script>
import { komshipAxiosIns, newAxiosIns } from '@/libs/axios'
import { isNumber } from '@/libs/helpers'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

extend('minFullname', {
  validate: value => value.length >= 3,
  message: 'Minimal 3 karakter',
})

extend('maxFullname', {
  validate: value => value.length < 41,
  message: 'Maksimal 40 karakter',
})

extend('onlyAlphabet', {
  validate: value => {
    const regex = /^[a-zA-Z ]+$/
    return regex.test(value)
  },
  message: 'Tidak boleh menggunakan spesial karakter dan angka',
})

extend('startWith8', {
  validate: value => value.startsWith('8'),
  message: 'Nomor telepon harus diawali dengan angka 8',
})

extend('minHP', {
  validate: value => value.length >= 9,
  message: 'Minimal 9 angka',
})

extend('maxHP', {
  validate: value => value.length < 15,
  message: 'Maksimal 14 angka',
})

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isNumber,
      checkWa: false,
      isSuccess: false,
      form: {
        fullname: '',
        number: '',
      },
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.number': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.CheckAvalableWhatsapp()
      }
    },
    deep: true,
  },
  methods: {
    preventNonNumeric(event) {
      const input = event.target

      // Allow backspace, arrow keys, delete, tab, and Ctrl+V
      if (
        ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'].includes(
          event.key,
        )
        || (event.ctrlKey && event.key === 'v')
      ) {
        return
      }

      if (!/^\d(?=(\.\d+)?)?$/.test(event.key)) {
        event.preventDefault()
      }
    },
    preventNumberPaste(event) {
      const clipboardData = event.clipboardData || event.originalEvent.clipboardData
      const pastedText = clipboardData.getData('text/plain')

      if (/^\d+$/.test(pastedText)) {
        // this.form.number = pastedText
      } else {
        event.preventDefault()
      }
    },
    async SubmitClaim() {
      const url = '/auth/api/v1/komcard/gamification/claim'

      try {
        const res = await newAxiosIns.request({
          method: 'POST',
          url,
          data: {
            phone_no: this.form.number,
            fullname: this.form.fullname,
          },
        })

        this.isSuccess = true
      } catch (error) {
        this.isSuccess = false
      }
    },
    async CheckAvalableWhatsapp() {
      if (this.form.number < 8) {
        return
      }
      const url = '/v1/check-wa-global'

      console.log(this.form.number)

      try {
        const res = await komshipAxiosIns.request({
          method: 'post',
          url,
          params: {
            phone_no: this.form.number,
          },
        })

        if (res.status === 200) {
          this.checkWa = true
        } else {
          throw new Error()
        }
      } catch (error) {
        this.checkWa = false
      }
    },
    async onOpen() {
      const url = '/v1/my-profile'

      const res = await komshipAxiosIns.request({
        method: 'post',
        url,
      })

      const { user_fullname, user_phone } = res.data.data

      this.form.fullname = user_fullname
      this.form.number = user_phone
    },
    submitForm() {
      this.SubmitClaim()
    },

    handleClaimGamification() {
      this.$bvModal.hide('modal-gamification')
      this.$emit('refresh')
    },
    handleClose() {
      this.isSuccess = false
      this.form = {
        fullname: '',
        number: '',
      }
    },
  },
}
</script>

<style>
.required-form::before {
  content: '*';
  color: red !important;
}

.disable-icon .form-control.is-invalid {
  background-image: none;
}

.number-phone .form-control.is-invalid {
  background-image: url('https://storage.googleapis.com/komerce/icon/whatsapp-off.svg');
}

.number-phone .form-control {
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-image: url('https://storage.googleapis.com/komerce/icon/whatsapp.svg');
}
</style>
